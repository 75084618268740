<template>
    <div>
        <!--轮播图-->
        <el-carousel trigger="click" height="380px" :interval="6000">
            <el-carousel-item v-for="(item, key) in bannerList" :key="key" @click.native="clickBanner(item)">
                <el-image class="banner" :src="item.imgUrl"/>
            </el-carousel-item>
        </el-carousel>

        <!-- 用户专区 -->
        <div class="user">
            <el-row :gutter="20">
                <!-- 院校指南 & 通识教育 -->
                <el-col :span="9">
                    <div class="card-to">
                        <div style="padding: 40px 0;">
                            <el-row type="flex" align="middle">
                                <el-col :span="18" style="display: flex">
                                    <svg-icon name="university" style="width: 45px; height: 45px;"/>
                                    <div style="margin-left: 15px;">
                                        <div class="title">
                                            院校指南
                                        </div>
                                        <div class="tips">
                                            招生复试全知道
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <router-link :to="{name: 'universityIndex'}">
                                        <el-button round style="color: #f29100" size="mini">
                                            去查看<i class="el-icon-arrow-right el-icon--right"/>
                                        </el-button>
                                    </router-link>
                                </el-col>
                            </el-row>
                        </div>
                        <div style="border-top: 1px solid hsla(0,0%,96%,.25); padding: 40px 0;">
                            <el-row type="flex" align="middle">
                                <el-col :span="18" style="display: flex">
                                    <svg-icon name="general" style="width: 45px; height: 45px;"/>
                                    <div style="margin-left: 15px;">
                                        <div class="title">
                                            通识教育
                                        </div>
                                        <div class="tips">
                                            法考论文简历，统统都有
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <router-link :to="{name: 'generalIndex'}">
                                        <el-button round style="color: #f29100" size="mini">
                                            去学习<i class="el-icon-arrow-right el-icon--right"/>
                                        </el-button>
                                    </router-link>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <!-- 用户学习记录 -->
                <el-col :span="15">
                    <div class="card-info">
                        <!--标题-->
                        <el-row>
                            <el-col :span="8">
                                <div class="title">
                                    学习数据
                                </div>
                            </el-col>
                            <el-col :span="16">
                                <div class="tips">
                                    已坚持学习 {{ getDays($store.state.userInfo.createTime) }} 天
                                </div>
                            </el-col>
                        </el-row>
                        <!-- 上次听课   -->
                        <el-row :gutter="20" style="margin-top: 40px;">
                            <el-col :span="16">
                                <div class="package-card" @click="toContentDetail(0)">
                                    <div class="package-title">
                                        {{ packageInfo.name || '专属计划定制中…' }}
                                    </div>
                                    <div class="package-tips">
                                        {{ packageInfo.name ? '上次学习专栏，继续上次的课程学习吧~' : '众多优秀好课，快来看看吧~' }}
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="course-card">
                                    <div class="course-title">
                                        {{ contentInfo.name || '获取学习包' }}
                                    </div>
                                    <div class="course-bottom">
                                        <div class="course-tips">
                                            {{ contentInfo.name ? ['录播课', '直播课'][contentType] : '精品好课' }}
                                        </div>
                                        <div class="course-btn">
                                            <el-button type="primary" round plain size="mini"
                                                       @click="toContentDetail(1)">
                                                <svg-icon name="play"/>
                                                {{ contentInfo.name ? '继续学习' : '前往专栏' }}
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <!--  推荐专区  -->
        <div class="recommend">
            <!--标题及倒计时-->
            <el-row>
                <el-col :span="6">
                    <div class="title">
                        课程推荐
                    </div>
                </el-col>
                <el-col :span="18">
                    <div style="text-align: right; font-size: 18px;">
                        <svg-icon name="downtime" style="width: 28px; height: 28px;"/>
                        今日距考试仅剩
                        <span style="font-weight: bold; font-size: 26px;">{{ day }}</span>
                        天
                    </div>
                </el-col>
            </el-row>
            <!-- 推荐学习包列表 -->
            <el-row :gutter="40">
                <el-col v-for="(item, key) in recommendList" :key="key" :span="6">
                    <router-link :to="'/package/detail/' + item.packageId">
                        <div>
                            <el-image class="img" fit="cover" :src="item.coverImgUrl"/>
                        </div>
                    </router-link>
                </el-col>
            </el-row>
        </div>

        <!--名师专区-->
        <div class="recommend">
            <!--标题及倒计时-->
            <div class="title">
                名师介绍
            </div>
            <vue-seamless-scroll :class-option="classOption" :data="teacherList" class="warp">
                <ul class="ul-item">
                    <li class="li-item" v-for="(teacher, key) in teacherList" :key="key">
                        <el-card class="teacher-card">
                            <div>
                                <el-avatar :src="teacher.imgUrl" :size="130"/>
                            </div>
                            <div class="name">
                                {{ teacher.name }}
                            </div>
                            <p class="desc">
                                {{ teacher.teacherDesc }}
                            </p>
                        </el-card>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
    name: "index",
    components: {    //组件
        vueSeamlessScroll
    },
    data() {
        return {
            day: 0,
            bannerList: [],
            recommendList: [],
            teacherList: [],
            classOption: {
                step: 0.4,
                limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true // 开启数据实时监控刷新dom
            },
            packageInfo: {},
            contentInfo: {},
            contentType: 0
        }
    },
    created() {
        // 1. 获取倒数天数
        this.$api.get('/index/index/getUserPostgraduateTime').then(res => {
            this.day = res.data
        }).catch(err => {
            console.log(err)
        })
        // 2. 获取当前轮播图
        this.$api.get('/index/index/getBannerList').then(res => {
            this.bannerList = res.data
        }).catch(err => {
            console.log('获取轮播图失败', err)
        })
        // 3. 获取当前学习进度
        if (this.$store.state.token) {
            this.$api.get('/index/index/getCurrentStudyContent').then(res => {
                this.packageInfo = res.packageInfo ? res.packageInfo : {}
                this.contentInfo = res.contentInfo ? res.contentInfo : {}
                this.contentType = res.contentType ? 1 : 0
            }).catch(err => {
                console.log('获取学习记录失败', err)
            })
        }
        // 4. 获取推荐的专栏
        this.$api.get('/index/index/getPackageRecommend', {params: {userType: this.$store.state.userType}}).then(res => {
            this.recommendList = res.data
        }).catch(err => {
            console.log('获取推荐专栏失败', err)
        })
        // 5. 获取推荐教师列表
        this.$api.get('/index/index/getTeacherList').then(res => {
            this.teacherList = res.data
        }).catch(err => {
            console.log('获取推荐教师失败', err)
        })
    },
    methods: {
        // 点击轮播图逻辑
        clickBanner(item) {
            // 判断类型
            if (item.type === 1) {
                // 学习包
                this.$router.push({path: '/package/detail/' + item.packageId})
            } else {
                // 打开新窗口，跳转url
                window.open(item.linkUrl)
            }
        },
        // 获取距离今天的间隔天数
        getDays(date) {
            if (!date) {
                return 0
            }
            const date1 = new Date(date.replace(/-/g, '/'));
            const date2 = new Date();
            return parseInt((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)); /*不用考虑闰年否*/
        },
        // 课程点击详情
        toContentDetail(val) {
            if (val === 0) {
                // 专栏课内容
                if (this.packageInfo.name) {
                    this.$router.push({path: '/package/detail/' + this.packageInfo.id})
                    //this.$router.push({name: 'packageDetail', params: {id: this.packageInfo.id}})
                    return
                }
            } else if (val === 1) {
                if (this.contentInfo.name) {
                    // 说明学习过，跳转至详情
                    if (this.contentType === 1) {
                        // 直播课
                        this.$router.push('/live/index/' + this.contentInfo.id)
                    } else if (this.contentType === 0) {
                        // 录播课
                        this.$router.push('/course/index/' + this.contentInfo.id)
                    }
                    return
                }
            }
            // 说明没有学习过，去专栏页面
            this.$router.push('/package/index')
        }
    }
}
</script>

<style scoped lang="scss">
.banner {
    border-radius: 7px;
    cursor: pointer;
    height: 380px;
}

.warp {
    overflow: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;

        &.ul-item {
            display: flex;

            .li-item {
                width: 300px;
                margin-right: 30px;
                color: #fff;
                text-align: center;
                padding-top: 30px;

                .teacher-card {
                    width: 300px;
                    border-radius: 10px;
                    cursor: pointer;

                    &:hover {
                        transition: all 0.2s;
                        transform: translate3d(0, -10px, 0);
                    }
                }

                .name {
                    font-size: 16px;
                    line-height: 16px;
                    color: #333;
                    margin: 15px 0;
                }

                .desc {
                    @include text-overflow(3);
                    line-height: 23px;
                    color: #666;
                    font-size: 13px;
                    margin: 15px 0;
                    text-align: justify;
                }
            }
        }
    }
}

.user {
    margin-top: 30px;

    .card-to {
        border-radius: 15px;
        background-color: $sr-main-color;
        color: #FFFFFF;
        padding: 20px;

        .title {
            font-size: 18px;
            line-height: 1.1;
            font-weight: 500;
            text-shadow: 0 0 #fff;
            margin-bottom: 10px;
        }

        .tips {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.1;
        }
    }

    .card-info {
        border-radius: 15px;
        padding: 20px;
        background-color: #FFFFFF;

        .title {
            font-size: 18px;
            font-weight: 500;
            text-shadow: 0 0 #131936;
            opacity: .8;
            color: #131936;
        }

        .tips {
            font-size: 14px;
            color: #8798ad;
            text-align: right;
        }

        .package-card {
            height: 190px;
            padding: 18px 20px;
            border-radius: 16px;
            background-color: rgba(238, 99, 78, .08);
            background-repeat: no-repeat;
            background-image: url(../assets/images/package.png);
            background-position: 320px 100px;
            background-size: 90px 90px;
            cursor: pointer;
            color: #8e4700;

            .package-title {
                margin-top: 10px;
                font-size: 25px;
            }

            .package-tips {
                margin-top: 30px;
            }
        }

        .course-card {
            height: 190px;
            padding: 18px 20px;
            border-radius: 16px;
            background-color: rgba(26, 143, 246, .15);
            background-repeat: no-repeat;
            cursor: pointer;

            .course-title {
                margin-top: 10px;
                font-size: 25px;
            }

            .course-bottom {
                display: flex;
                align-content: center;
                justify-content: center;
                margin-top: 30px;

                .course-tips {
                    color: #8798ad;
                    margin-top: 4px;
                    flex: 1;
                }

                .course-btn {
                    text-align: right;
                    flex: 1;
                }
            }
        }
    }
}

.recommend {
    margin-top: 30px;
    margin-bottom: 30px;

    &.recommend:nth-of-type(1) {
        margin-top: 50px;
    }

    &.recommend:last-child {
        margin-bottom: 50px;
    }

    .title {
        position: relative;
        padding-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        color: #262937;
    }

    .title:before {
        content: "";
        position: absolute;
        left: 0;
        top: 1px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $sr-main-color;
        opacity: .3;
    }

    .img {
        border-radius: 10px;
        cursor: pointer;
        object-fit: cover;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 265px;
        height: 291px;
        overflow: hidden;

        &:hover {
            transition: all 0.2s;
            transform: translate3d(0, -7px, 0);
        }
    }


}
</style>